import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Calendly from "../components/Calendly"


import logoNewpricer from "../images/logo_podium.png"
import logoAtpco from "../images/references/ATPCO_partner.png"
import logoKlm from "../images/references/logo_KLM.png"
import logoDelta from "../images/references/Logo_Delta.png"
import logoAlitalia from "../images/references/logo_Alitalia.png"
import logoAirfrance from "../images/references/Logo_airfrance.png"
import logoMalaysia from "../images/references/Malaysia-Airlines-Logo.png"
import logoscenes from "../images/references/logo_scenes_nationales.jpeg"
import logoministere from "../images/references/Ministere_Culture.png"
import logoOM from "../images/references/OM.png"
import curve from "../images/Takeoffblue.jpg"
import wm from "../images/theatre.png"
import om from "../images/yield.jpg"
import ind from "../images/industry.png"
import win from "../images/win.svg"
import lemon from "../images/lemonade.svg"
import team from "../images/team.svg"
import perf from "../images/graphic.svg"
import fast from "../images/Fast.svg"
import target from "../images/Target.svg"
import info from "../images/Information.svg"
import process from "../images/Process.png"
import logoFrench from "../images/Logo_French_Tech.png"
import logoRegion from "../images/Logo-region.png"
import logoUnitec from "../images/unitec.png"
import stade from "../images/velodrome2.jpg"
import contraintes from "../images/Contraintes2.png"
import ecranom from "../images/soft.png"
import impacts from "../images/impacts.png"
import air from "../images/screen1.png"



const TeomenoFrPage = () => (
    <Layout navbarScrollOpacity>
        <SEO title="Making Pricing Easy · Pricing Solutions" />


        {/* Banniere d'entrée */}
        <div className="banner">
            <img src={stade} alt="velodrome" className="w-100"></img>
            <div className="container h-100 p-3">
                <div className="h-100 d-flex justify-content-right align-items-end ">

                    <div className="row mb-1 pb-1 d-flex align-items-center children-mx-3">
                        <div className="row mb-1 pb-1 d-flex flex-column align-items-center children-mx-3">
                            <h1 className="title font-weight-lighter text-danger pl-4 ons">
                                t e o m e n o
                            </h1>
                            <div>
                                <div className="row d-flex align-items-around">
                                    <h6 className="font-weight-lighter text-danger pl-4 ons">
                                        by
                                    </h6>
                                    <img src={logoNewpricer} id="newpricer" alt="logo_newpricer" className="max-height-6rem ml-2 pl-5 ons"></img>
                                    <h6 className="font-weight-lighter text-danger pl-4 ons">
                                        n e w p r i c e r
                                    </h6>
                                </div>
                            </div>
                        </div>
                        <div className="ml-5 pl-5 us">
                            <h1 className="h1 font-weight-lighter text-dark ml-5 pl-5">
                                Jouez à guichet fermé !
                            </h1>
                        </div>
                    </div>
                </div>
            </div>

        </div>



        {/* Besoins */}
        <div className="row bleuOM p-4">
            {/* Besoins/Entetes */}
            <div className="container">

                <h3 className="h1 font-weight-lighter text-center text-light p-2 m-2">
                    Les revenus de billetterie des clubs français sont sous-estimés
                </h3>
                <h3 className="h3 font-weight-lighter text-center text-light p-2 m-2">
                    car dynamiser son offre nécessite de résoudre de nombreux problèmes opérationnels
                </h3>
            </div>
            {/* Besoins/images */}
            <div className="container text-light">
                <div className="row d-flex align-items-center justify-content-center">
                    <img className="w-100 mb-3" src={contraintes} alt="Contraintes operationnelles"></img>
                </div>
            </div>
        </div>

        {/* Process */}
        <div className="row bg-light p-5">
            <div className="container">
                <h3 className="h1 text-dark px-5 text-center">Teomeno : une solution intuitive et performante pour remplir le stade
                </h3>
                <h3 className="h3 font-weight-lighter text-center text-dark p-2 m-2">
                    Un outil complet d'aide à la décision
                </h3>
                {/* Process/ image */}
                <div className="row bg-light">
                    <div className="col">
                    <img className="w-100" src={ecranom} alt="fonctionnalites"></img>
                    </div>
                    <div className="col d-flex flex-column justify-content-around">
                        <div className="d-flex justify-content-between">
                            <div className="col-1 text-danger"><h4><i className="fas fa-chart-area"></i></h4></div>
                            <div className="col flex-grow-1 blue"><h5>Analyse les ventes et anticipe la demande</h5></div>
                        </div>
                        <div className="d-flex justify-content-between">
                            <div className="col-1 text-danger"><h4><i className="fas fa-magnet"></i></h4></div>
                            <div className="col flex-grow-1 blue"><h5>Mesure l'impact de vos actions sur le public</h5></div>
                        </div>
                        <div className="d-flex justify-content-between">
                            <div className="col-1 text-danger"><h4><i className="fas fa-handshake"></i></h4></div>
                            <div className="col flex-grow-1 blue"><h5>Alerte et recommande la meilleure décision</h5></div>
                        </div>
                        <div className="d-flex justify-content-between">
                            <div className="col-1 text-danger"><h4><i className="fas fa-award"></i></h4></div>
                            <div className="col flex-grow-1 blue"><h5>Simple et ergonomique</h5></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        {/* Impacts */}
        <div className="bleuOM py-3 text-white">
            <div className="container w-50 mb-5">
                <h3 className="h3 font-weight-lighter text-center text-white p-2 m-2">
                    Impacts mesurés sur des cas réels :
                </h3>
                <div className="row d-flex align-items-end justify-content-center">
                    <div className="col d-flex flex-column justify-content-between align-items-center">
                        <h4 className="text-center p-3">Sans Teomeno</h4>
                        <div className="sansTeomeno bg-light text-dark text-center p-5" >11 %</div>
                    </div>
                    <div className="col d-flex flex-column justify-content-between align-items-center">
                        <h4 className="text-center p-3">Avec Teomeno</h4>
                        <div className="avecTeomeno bg-danger text-light text-center p-5" >35 %</div>
                    </div>

                </div>
                <h3 className="h3 font-weight-lighter text-center text-white p-2 m-2">Proportion moyenne de la billetterie dans les revenus du club</h3>
            </div>
        </div>

        {/* Why us? */}
        <div className="row foot p-4">
            <div className="container">
                <h3 className="h1 text-light py-3">
                    Pourquoi nous ?
                </h3>
                <h3 className="h3 font-weight-lighter text-center text-light p-2 m-2">
                    Teomeno a été crée par des experts de la billetterie et du revenue issus des grandes compagnies aériennes
                </h3>
                <h3 className="h3 font-weight-lighter text-center text-light p-2 m-2">
                    Nous avons adapté nos algorithmes pour vous fournir le meilleur outil du marché
                </h3>
            </div>
            <div className="container">
                <div className="row d-flex align-items-center justify-content-center">
                    <div className="col card-product m-3">
                        <img className="h-50" src={win} alt="successful team"></img>
                        <div className="p-1">
                            <h2>Basé sur l'experience</h2>
                            <p className="h1 text-justify">Nous avons une profonde connaissance des techniques d'optimisation du revenu, parce que nous l'avons fait pour nos propres marchés.</p>
                        </div>
                    </div>
                    <div className="col card-product m-3">
                        <img className="h-50" src={team} alt="collaborative support"></img>
                        <div className="p-1">
                            <h2>A votre écoute</h2>
                            <p className="h1 text-justify">Notre marque de fabrique est la transmission de savoirs : nous vous accompagnons tant sur les outils, que sur les meilleures pratiques du métier. </p>
                        </div>
                    </div>
                </div>
                <div className="row d-flex align-items-center justify-content-center">
                    <div className="col card-product m-3">
                        <img className="h-50" src={lemon} alt="easy simple time saved"></img>
                        <div className="p-1">
                            <h2>simple, intuitif, performant</h2>
                            <p className="h1 text-justify">Ce qui se comprend bien, s'énonce clairement. Nous voulons que les outils soient agréable à regarder et à utiliser.</p>
                        </div>
                    </div>
                    <div className="col card-product m-3">
                        <img className="h-50" src={perf} alt="efficiency IA ML"></img>
                        <div className="p-1">
                            <h2>basé sur l'IA et des règles métiers</h2>
                            <p className="h1 text-justify">Tous nos algorithmes IA sont performants car contrôlés par des règles métiers strictes.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        {/* They trust us */}
        <div className="row p-4">
            <div className="container">
                <h3 className="h1 text-dark py-3">
                   Ils nous font confiance
                </h3>
            </div>
            <div className="container">
                <div className="row my-5 text-center">
                    <div className="col">
                        <img className="logo1" src={logoscenes} alt="Scenes Nationales"></img>
                    </div>
                    <div className="col">
                        <img className="logo1" src={logoOM} alt="OM"></img>
                    </div>
                </div>
            </div>
        </div>


        {/* Resquest a meeting
        <div className="bleuOM py-3 text-white">
            <div className="container">
                <div className="row d-flex align-items-center">
                  <div id="schedule" className="col text-center">
                       < Calendly />
                   </div>
                </div>
            </div>
        </div> */}
        {/* They trust us */}

        <div className="row foot p-5">
            <div className="container">
                <h3 className="row h1 text-light px-5">
                    Autres secteurs
                </h3>
                <h3 className=" h3 font-weight-lighter text-center text-light p-5">
                    Nous adaptons notre savoir-faire à vos spécificités
                </h3>
                <div className="row align-items-start justify-content-center  p-2">

                    <div className="card-box mx-3">
                        <div className="card-category culture py-3">
                            T H E A T R E S
                        </div>
                        <div className="card-content px-1">
                            <h5 className="pt-3">Développer les publics</h5>
                            <p className="text-center">Analyse des ventes pour accroître la satisfaction client</p>
                            <div className="icon"><img src={wm} alt="airline fare management"></img></div>
                            <div className="w-100 text-secondary d-flex align-items-center justify-content-around py-3">
                                <div className="text-center benefit">
                                    <i className="fas fa-adjust"></i>
                                    <p>pilotage</p>
                                </div>
                                <div className="text-center benefit">
                                    <i className="fas fa-tachometer-alt"></i>
                                    <p>réactivité</p>
                                </div>
                                <div className="text-center benefit">
                                    <i className="fas fa-people-arrows"></i>
                                    <p>public</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card-box mx-3">
                        <div className="card-category airline py-3">
                            A I R L I N E S
                        </div>
                        <div className="card-content px-1">
                            <h5 className="p-3">Etre compétitif</h5>
                            <p className="text-center">Ajuster ses offres rapidement et sans erreurs</p>
                            <div className="icon"><img src={air} alt="airline fare management"></img></div>
                            <div className="w-100 text-secondary d-flex align-items-center justify-content-around py-3">
                                <div className="text-center benefit">
                                    <i className="fas fa-chart-pie"></i>
                                    <p>offres</p>
                                </div>
                                <div className="text-center benefit">
                                    <i className="fas fa-users"></i>
                                    <p>demande</p>
                                </div>
                                <div className="text-center benefit">
                                    <i className="fas fa-chart-line"></i>
                                    <p>profit</p>
                                </div>
                            </div>
                            <a href="/" className="learn btn btn-outline-danger font-weight-lighter">
                                En savoir plus...
                            </a>
                        </div>
                    </div>




                    <div className="card-box mx-3">
                        <div className="card-category commerce py-3">
                            I N D U S T R Y
                        </div>
                        <div className="card-content px-1">
                            <h5 className="p-3">Protéger les marges</h5>
                            <p className="text-center">Ajuster les prix en fonction des concurrents et des coûts de matières premières</p>
                            <div className="icon"><img src={ind} alt="stadium yield management"></img></div>
                            <div className="w-100 text-secondary d-flex align-items-center justify-content-around py-3">
                                <div className="text-center benefit">
                                    <i className="fas fa-chart-pie"></i>
                                    <p>offres</p>
                                </div>
                                <div className="text-center benefit">
                                    <i className="fas fa-compass"></i>
                                    <p>coûts</p>
                                </div>
                                <div className="text-center benefit">
                                    <i className="fas fa-chart-line"></i>
                                    <p>profit</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>


        <div className="row bg-light p-5">
            <div className="container">
                <h3 className="h1 text-dark py-3">
                    Nos partenaires
                </h3>
            </div>
            <div className="container">
                <div className="row my-5 text-center align-items-center">
                    <div className="col">
                        <img className="logo2" src={logoFrench} alt="FrenchTech"></img>
                    </div>
                    <div className="col ">
                        <img className="logo2" src={logoUnitec} alt="Unitec"></img>
                    </div>
                    <div className="col ">
                        <img className="logo" src={logoRegion} alt="NouvelleAquitaine"></img>
                    </div>
                    <div className="col ">
                        <img className="logo" src={logoAtpco} alt="ATPCO"></img>
                    </div>
                    <div className="col ">
                        <img className="logo1" src={logoministere} alt="Ministere de la Culture"></img>
                    </div>
                </div>
            </div>
        </div>

        <div className="h-150vh bleuOM p-4 photo">
            <div className="container">
                <h3 className="h1 text-light py-3">
                    Nous contacter
                </h3>
            </div>
            <div className="row">
                <div className="col us d-flex text-center justify-content-center align-items-center">
                    <i className="col h1 fas fa-id-card text-light"></i>
                </div>
                <div className="col h4 font-weight-light text-light text-justify ons mx-5">
                    <h1 className="h1 font-weight-lighter text-light ">
                        newpricer
                    </h1>
                    <p className="">
                        2 Rue Doyen Georges Brus
                    </p>
                    <p className="">
                        33600 Pessac
                    </p>
                    <p className="">
                        France
                    </p>
                    <p className="">
                        contact@newpricer.com
                    </p>
                </div>
                {/*   <div className="col-3 d-flex text-center justify-content-center align-items-center us">

                       < Calendly />

                     </div> */}
            </div>
        </div>Gauche

    </Layout>
)

export default TeomenoFrPage
